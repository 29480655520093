<template>
  <div class="home">
    <div class="banner">
      <img src="../../assets/topHeader.png" alt="" class="bannerImg">
    </div>
    <ul class="useList">
      <li class="useImgWrapper" @click="refresh">
        <img src="../../assets/homeLogo.png" alt="" class="useImg">
        <span class="useTitle">首页</span>
      </li>
      <li class="useImgWrapper" @click="goLink('order')">
        <img src="../../assets/orderLogo.png" alt="" class="useImg">
        <span class="useTitle">订单查询</span>
      </li>
      <li class="useImgWrapper" @click="gokf()">
        <img src="../../assets/kfLogo.png" alt="" class="useImg">
        <span class="useTitle">联系客服</span>
      </li>
      <li class="useImgWrapper" @click="goLink('report')">
        <img src="../../assets/complaintLogo.png" alt="" class="useImg">
        <span class="useTitle">投诉</span>
      </li>
    </ul>
    <!-- <ul class="ulWrapper">
      <li v-for="(item, index) in list" :key="index" class="listWrapper" @click="goPayOrder(item.id)">
        <div class="list-item">
          <img :src="item.imageUrl" alt="" class="listImg">
          <p class="listTitle">{{ item.goodsName }}</p>
          <p class="listTitleRight">月销：999999</p>
        </div>
      </li>
    </ul> -->
    <!-- 列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      offset="50"
      finished-text="没有更多了"
      @load="onLoad"
      class="listWrapper"
    >
      <div class="listContentWrapper">
        <van-cell v-for="(item, index) in list" :key="index" class="listContent">
          <div class="goodsImgWrapper">
            <img :src="item.imageUrl" alt="" class="goodsImg">
          </div>
          <ul class="goodsMessageWrapper">
            <li class="title">{{ item.title }}</li>
            <li class="content">{{ item.description }}</li>
            <li class="operation">
              <span class="price">售价： {{ parseFloat(item.price / 100).toFixed(2) }} 元</span>
              <van-button type="info" class="placeBtn" size="small" @click="goPayOrder(item.pageId)">
                立即办理<van-icon name="arrow" />
              </van-button>
            </li>
          </ul>
        </van-cell>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getHomeList, getTitle } from '../../api/api'

export default {
  name: 'home',
  props: {
    id: {
      require: true
    }
  },
  data () {
    return {
      list: [],
      loading: false,
      finished: false, //是否有更多
      page: 1,
      pageSize: 10,
      count: null
    }
  },
  created () {
    this.$store.commit('setUserId', this.$route.params.id)
    this.$store.commit('setUrl', window.location.href.split('/home')[0])
    this._getHomeData()
    this._getTitle()
  },
  methods: {
    // 查询列表
    _getHomeData () {
      this.loading = true
      let params = {
        page: this.page,
        pageSize: this.pageSize
      }
      getHomeList(this.$route.params.id, params).then(res => {
        if (res && res.code === 200) {
          this.count = res.result.total
          if (res.code === 200) {
            res.result.records.forEach(item => {
              this.list.push(item)
            });
            this.loading = false
          }
        } else {
          this.loading = false
          this.finished = true
        }
      })
    },
    // 查询标题
    _getTitle () {
      getTitle(this.$route.params.id).then(res => {
        console.log(res)
        if (res.code === 200) {
          window.document.title = res.result.pageTitle
        }
      })
    },
    refresh () {
      this.list = []
      this.count = null
      this.page = 1
      this.finished = false
      this._getHomeData()
    },
    onLoad() {
      // 异步更新数据
      console.log('进来了')
      setTimeout(() => {
        if (this.list.length >= this.count) {
          this.finished = true
        } else {
          this.finished = false
          this.page++
          this._getHomeData()
        }
      }, 1000)
    },
    // 跳转商品页
    goPayOrder (id) {
      this.$router.push({
        path: `/placeAnOrder/index/${id}`,
      })
    },
    goLink(type) {
      let url = new Map([
        ['order', '/order'],
        ['report', '/report']
      ])
      this.$router.push({
        path: url.get(type),
      })
    },
    gokf () {
      window.location.href = 'https://im-c.zmyiot.com/?appid=5a0762d9'
    }
  }
}
</script>

<style lang="less" scoped>
  .home {
    min-height: 100%;
    background-color: #F7F6F2;
    margin-bottom: 50px;
    .banner {
      height: 173px;
      margin: 0;
      padding: 0;
      .bannerImg {
        width: 100%;
        height: 173px;
      }
    }
    // 功能列表logo
    .useList {
      display: flex;
      background-color: #fff;
      .useImgWrapper {
        margin: 10px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        .useImg{
          width: 39px;
          height: 39px;
        }
        .useTitle {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
    .listContentWrapper {
      margin: 10px;
      .listContent {
        margin-bottom: 10px;
        background-color: #fff;
        .goodsImg {
          width: 119px;
          height: 119px;
          border-radius: 6px;
        }
        .goodsMessageWrapper {
          margin-left: 13px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 18px;
            font-weight: bold;
            color: #333;
          }
          .content {
            color: #666;
            font-weight: 500;
            font-size: 14px;
            padding: 10px 0;
            line-height: 20px;
          }
          .operation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .price {
              color: #F23A54;
              font-weight: bold;
              font-size: 18px;
            }
            .placeBtn {
              font-size: 15px;
              font-weight: bold;
              color: #fff;
              border-radius: 7px;
              padding: 10px;
            }
          }
        }
      }
    }
  }
  /deep/ .van-cell{
    padding: 10px;
  }
  /deep/ .van-cell__value {
    display: flex;
    justify-content: space-between;
  }
</style>